import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box } from "@mui/material";
import { mkConfig } from "export-to-csv";
import { baseUrl } from "../../utils/baseUrl";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("name", {
    header: "Client Name",
    size: 40,
  }),
  columnHelper.accessor("mobileNo", {
    header: "Mobile Number",
    size: 40,
  }),
];

const CreateNewSIP = () => {
  const [clientList, setClientList] = useState([]);
  const Token = sessionStorage.getItem("token");
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    FetchClientList();
  }, []);


  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableRowSelection: false,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell }) => [
      <Box>
        <Button
          type="button"
          variant="contained"
          onClick={() => addSIP(cell.row.original._id)}
          style={{ textTransform: "none" }}
        >
          Place Order
        </Button>
      </Box>,
    ],
  });
  const FetchClientList = async () => {
    try {
      const url = `${baseUrl}/kycClientList`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const clients = response.data.data;
      setClientList(clients);
      setEmployees(clients);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const addSIP = (clientId) => {
     navigate(`/final-create-sip/${clientId}`);
    // navigate("/final-create-sip", { state: { client } });
  };

  return <MaterialReactTable table={table} />;
};
export default CreateNewSIP;
