import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Navbar1 from "../../../components/Navbar";
import Footer from "../../../landing_page/Footer";
import { baseUrl } from "../../../utils/baseUrl";
import axios from "axios";

// Import the necessary Firebase functions
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, runTransaction } from "firebase/database";

// Initialize Firebase (Add your Firebase configuration here)
const firebaseConfig = {
  apiKey: "AIzaSyCYAGdxyVPDp6vctJ2VGe5YFIygtHkSVqk",
  authDomain: "bharat-batuk.firebaseapp.com",
  projectId: "bharat-batuk",
  storageBucket: "bharat-batuk.appspot.com",
  messagingSenderId: "742339172499",
  appId: "1:742339172499:web:f416a146d39323ca8a1566",
  measurementId: "G-T501KNRSCN",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const ViewBlog = () => {
  const { blogId } = useParams();
  const [post, setPost] = useState([]);
  const [blogDetails, setBlogDetails] = useState([]);
  const [viewCount, setViewCount] = useState(0);

  // console.log(post.metatag, ">>>>>>>>Blog details");

  useEffect(() => {
    fetchBlogDetails(blogId);
  }, [blogId]);

  const fetchBlogDetails = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin_panel/blog_Details/${blogId}`
      );
      setBlogDetails(response.data.data);
      setPost(response.data.data[0]);
    } catch (error) {
      console.log("Failed to fetch blog details.");
    }
  };

  useEffect(() => {
    // Increment the view count when the page loads
    const pageViewsRef = ref(database, `pageViews/${blogId}`);
    runTransaction(pageViewsRef, (current) => (current || 0) + 1);

    // Fetch the current view count
    const handleValueChange = (snapshot) => {
      const count = snapshot.val();
      setViewCount(count || 0);
    };

    // Set up the listener
    const unsubscribe = onValue(pageViewsRef, handleValueChange);

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [blogId]);

  const metaKeywords = post?.title
    ? post.title
        .replace(/[^\w\s]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()
    : "";

  return (
    <>
      <Navbar1 />
      <div className="blog-content-container">
        {/* SEO using react-helmet */}
        <Helmet>
          <meta charSet="utf-8" />
          <title>{post.title}</title>
          <meta name="description" content={post.description} />
          <meta name="keywords" content={post.metatag} />{" "}
          <link
            rel="canonical"
            href={`https://partner.batuk.in/blog/${metaKeywords}`}
          />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.description} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={post.image} />
        </Helmet>
        {blogDetails.length > 0 &&
          blogDetails.map((blog, index) => (
            <div key={index} className="blog-content">
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </div>
          ))}
      </div>
      {/* <p>Number of views: {viewCount}</p> */}
      <Footer />
    </>
  );
};

export default ViewBlog;

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import Navbar1 from "../../../components/Navbar";
// import Footer from "../../../landing_page/Footer";
// import { baseUrl } from "../../../utils/baseUrl";
// import axios from "axios";

// // Import the necessary Firebase functions
// import { initializeApp } from "firebase/app";
// import { getDatabase, ref, onValue, runTransaction } from "firebase/database";

// // Initialize Firebase (Add your Firebase configuration here)
// const firebaseConfig = {
//   apiKey: "AIzaSyCYAGdxyVPDp6vctJ2VGe5YFIygtHkSVqk",
//   authDomain: "bharat-batuk.firebaseapp.com",
//   projectId: "bharat-batuk",
//   storageBucket: "bharat-batuk.appspot.com",
//   messagingSenderId: "742339172499",
//   appId: "1:742339172499:web:f416a146d39323ca8a1566",
//   measurementId: "G-T501KNRSCN",
// };

// const app = initializeApp(firebaseConfig);
// const database = getDatabase(app);

// const ViewBlog = () => {
//   const { blogId } = useParams();
//   const [blogDetails, setBlogDetails] = useState([]);
//   const [viewCount, setViewCount] = useState(0);

//   useEffect(() => {
//     fetchBlogDetails(blogId);
//   }, [blogId]);

//   const fetchBlogDetails = async () => {
//     try {
//       const response = await axios.get(
//         `${baseUrl}/admin_panel/blog_Details/${blogId}`
//       );
//       console.log(response.data.data, ">>>>>>>>>>>>");
//       setBlogDetails(response.data.data);
//     } catch (error) {
//       console.log("Failed to fetch blog details.");
//     }
//   };

//   useEffect(() => {
//     // Increment the view count when the page loads
//     const pageViewsRef = ref(database, `pageViews/${blogId}`);
//     runTransaction(pageViewsRef, (current) => (current || 0) + 1);

//     // Fetch the current view count
//     const handleValueChange = (snapshot) => {
//       const count = snapshot.val();
//       setViewCount(count || 0);
//     };

//     // Set up the listener
//     const unsubscribe = onValue(pageViewsRef, handleValueChange);

//     // Clean up the listener on component unmount
//     return () => unsubscribe();
//   }, [blogId]);

//   return (
//     <>
//       <Navbar1 />
//       <div>
//         {blogDetails.length > 0 &&
//           blogDetails.map((blog, index) => (
//             <div key={index}>
//               <div dangerouslySetInnerHTML={{ __html: blog.content }} />
//             </div>
//           ))}
//       </div>
//       <p>Number of views: {viewCount}</p>
//       <Footer />
//     </>
//   );
// };

// export default ViewBlog;

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import Navbar1 from "../../../components/Navbar";
// import Footer from "../../../landing_page/Footer";
// import { baseUrl } from "../../../utils/baseUrl";
// import axios from "axios";

// import firebase from "firebase";
// import "firebase/database";

// // Initialize Firebase (Add your Firebase configuration here)
// const firebaseConfig = {
//   apiKey: "AIzaSyCYAGdxyVPDp6vctJ2VGe5YFIygtHkSVqk",
//   authDomain: "bharat-batuk.firebaseapp.com",
//   projectId: "bharat-batuk",
//   storageBucket: "bharat-batuk.appspot.com",
//   messagingSenderId: "742339172499",
//   appId: "1:742339172499:web:f416a146d39323ca8a1566",
//   measurementId: "G-T501KNRSCN",
// };

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

// const database = firebase.database();

// const ViewBlog = () => {
//   const { blogId } = useParams();
//   const [blogDetails, setBlogDetails] = useState("");

//   useEffect(() => {
//     fetchBlogDetails(blogId);
//   }, [blogId]);

//   const fetchBlogDetails = async () => {
//     try {
//       const response = await axios.get(
//         `${baseUrl}/admin_panel/blog_Details/${blogId}`
//       );
//       console.log(response.data.data, ">>>>>>>>>>>>");
//       setBlogDetails(response.data.data);
//     } catch (error) {
//       console.log("Failed to fetch blog details.");
//     }
//   };

//   const [viewCount, setViewCount] = useState(0);

//   useEffect(() => {
//     // Increment the view count when the page loads
//     const pageViewsRef = database.ref("pageViews/blogPage");
//     pageViewsRef.transaction((current) => (current || 0) + 1);

//     // Fetch the current view count
//     pageViewsRef.on("value", (snapshot) => {
//       const count = snapshot.val();
//       setViewCount(count || 0);
//     });

//     // Clean up the listener on unmount
//     return () => pageViewsRef.off();
//   }, []);

//   return (
//     <>
//       <Navbar1 />
//       <div>
//         {blogDetails.length &&
//           blogDetails.map((blog, index) => (
//             <div key={index}>
//               <div dangerouslySetInnerHTML={{ __html: blog.content }} />
//             </div>
//           ))}
//         <p>Number of views: {viewCount}</p>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default ViewBlog;

// import React, { useEffect, useRef } from "react";
// import "../../../assets/css/viewblog.css";
// import Navbar1 from "../../../components/Navbar";
// import Footer from "../../../landing_page/Footer";

// const ViewBlog = () => {
//   const toggleRef = useRef(null);
//   const sidebarToggleRef = useRef(null);
//   const sidebarRef = useRef(null);
//   const readingProgressBarRef = useRef(null);

//   useEffect(() => {
//     const toggle = toggleRef.current;
//     const sidebarToggle = sidebarToggleRef.current;
//     const sidebar = sidebarRef.current;
//     const readingProgressContainer = document.querySelector(
//       ".reading-progress-container"
//     );
//     const readingProgressBar = readingProgressBarRef.current;

//     if (toggle) {
//       toggle.addEventListener("click", () => {
//         document.body.classList.toggle("dark-mode");
//       });
//     }

//     if (sidebarToggle) {
//       sidebarToggle.addEventListener("click", () => {
//         document.body.classList.toggle("sidebar-visible");
//       });
//     }

//     if (sidebar && readingProgressContainer && readingProgressBar) {
//       // Clear existing sidebar links to avoid duplicates
//       sidebar
//         .querySelectorAll(".sidebar-link")
//         .forEach((link) => link.remove());

//       const headers = document.querySelectorAll(
//         ".post-content :is(h1, h2, h3)"
//       );

//       headers.forEach((header, index) => {
//         const headerId = header.id || `header${index + 1}`;
//         header.id = headerId;
//         header.setAttribute("tabindex", "0");

//         const link = document.createElement("a");
//         link.href = `#${headerId}`;
//         link.textContent = header.textContent;
//         link.className = "sidebar-link";
//         link.dataset.headerId = headerId;

//         if (sidebar.contains(readingProgressContainer)) {
//           sidebar.insertBefore(link, readingProgressContainer);
//         } else {
//           sidebar.appendChild(link); // Fallback to appending if readingProgressContainer is not found
//         }
//       });

//       const sidebarLinks = document.querySelectorAll(".sidebar-link");
//       sidebarLinks.forEach((link) => {
//         link.addEventListener("click", (event) => {
//           event.preventDefault();

//           const targetId = link.getAttribute("href").substring(1);
//           const targetHeader = document.getElementById(targetId);

//           if (targetHeader) {
//             const headerOffset =
//               targetHeader.getBoundingClientRect().top +
//               window.pageYOffset -
//               window.innerHeight / 2 +
//               targetHeader.offsetHeight / 2;
//             window.scrollTo({ top: headerOffset, behavior: "smooth" });
//           }
//         });
//       });

//       // Handle scroll for progress bar
//       function handleScroll() {
//         const headers = document.querySelectorAll(
//           ".post-content :is(h1, h2, h3)"
//         );
//         let lastPassedHeaderId = null;

//         headers.forEach((header) => {
//           if (header.getBoundingClientRect().top < window.innerHeight / 2) {
//             lastPassedHeaderId = header.id;
//           }
//         });

//         const sidebarLinks = document.querySelectorAll(".sidebar-link");
//         sidebarLinks.forEach((link) => {
//           if (lastPassedHeaderId === link.dataset.headerId) {
//             link.classList.add("active");
//           } else {
//             link.classList.remove("active");
//           }
//         });

//         // Ensure the post-content and paragraphs exist
//         const content = document.querySelector(".post-content");
//         if (!content) return;

//         const paragraphs = content.querySelectorAll("p");
//         if (paragraphs.length === 0) return;

//         const firstHeader = headers[0];
//         const lastParagraph = paragraphs[paragraphs.length - 1];

//         const startOffset =
//           firstHeader.getBoundingClientRect().top +
//           window.pageYOffset -
//           window.innerHeight / 2;
//         const endOffset =
//           lastParagraph.getBoundingClientRect().bottom +
//           window.pageYOffset -
//           window.innerHeight / 2;

//         const scrollTop = window.pageYOffset;
//         const scrollRange = endOffset - startOffset;
//         const scrollPosition = scrollTop - startOffset;

//         let progress = scrollPosition / scrollRange;
//         progress = Math.max(0, Math.min(1, progress));

//         if (readingProgressBarRef.current) {
//           readingProgressBarRef.current.style.width = progress * 100 + "%";
//         }
//       }

//       document.addEventListener("scroll", throttle(handleScroll, 25));
//     }

//     return () => {
//       if (toggle) {
//         toggle.removeEventListener("click", () => {
//           document.body.classList.toggle("dark-mode");
//         });
//       }

//       if (sidebarToggle) {
//         sidebarToggle.removeEventListener("click", () => {
//           document.body.classList.toggle("sidebar-visible");
//         });
//       }
//     };
//   }, []);

//   function throttle(func, limit) {
//     let lastFunc;
//     let lastRan;
//     return function () {
//       const context = this;
//       const args = arguments;
//       if (!lastRan) {
//         func.apply(context, args);
//         lastRan = Date.now();
//       } else {
//         clearTimeout(lastFunc);
//         lastFunc = setTimeout(() => {
//           if (Date.now() - lastRan >= limit) {
//             func.apply(context, args);
//             lastRan = Date.now();
//           }
//         }, limit - (Date.now() - lastRan));
//       }
//     };
//   }

//   return (
//     <>
//       <Navbar1 />
//       <button ref={toggleRef} className="sidebar-toggle">
//         ☰
//       </button>
//       <div className="intro">
//         <h2>The Delightful World of Crayfish.</h2>
//         <p>
//           Crayfish, also known as crawfish or crawdads in certain regions,
//           embody the fascinating diversity of freshwater ecosystems. These
//           intriguing creatures, often found scuttling along the muddy bottoms of
//           streams and lakes, offer a glimpse into the complex and vibrant
//           underwater world.
//         </p>
//         <p>
//           Amidst the aquatic ballet of flowing plants and darting fish, crayfish
//           contribute a unique element of dynamism and resilience. These
//           creatures, often overlooked, are the engineers of the underwater
//           world, creating intricate burrows and tunnels that provide shelter and
//           breeding grounds not only for themselves but for a host of other
//           organisms. This architectural prowess enhances the structural
//           complexity of their habitats, promoting biodiversity and fostering a
//           robust ecosystem.
//         </p>
//       </div>
//       <div className="sidebar-container">
//         <div className="sidebar-wrapper">
//           <div ref={sidebarRef} className="sidebar">
//             <div className="reading-progress-container">
//               <div
//                 ref={readingProgressBarRef}
//                 className="reading-progress-bar"
//               ></div>
//             </div>
//           </div>
//           <div className="author-box">
//             <img
//               src="https://gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
//               alt="Author's Gravatar"
//               className="author-gravatar"
//             />
//             <div className="author-info">
//               <span className="author-title">Author</span>
//               <span className="author-name">John Doe</span>
//             </div>
//             <span
//               id="dark-mode-toggle"
//               className="material-symbols-outlined light-icon"
//             ></span>
//           </div>
//         </div>
//         <div className="post-content">
//           <h3 className="header-one">Habitat and Distribution</h3>
//           <p>
//             Crayfish make their homes in a variety of freshwater environments.
//             From swiftly flowing rivers to the still waters of ponds and lakes,
//             they are masters of adaptation. Their ability to thrive in diverse
//             habitats has led to a wide distribution, with species found across
//             the globe, each adapted to its unique environmental niche.
//           </p>
//           <h3>Physical Characteristics</h3>
//           <p>
//             Characterized by their robust claws and armored exoskeletons,
//             crayfish are the warriors of the aquatic realm. Their striking
//             appearance, often adorned with hues of green, red, or brown, not
//             only serves as camouflage but also as a warning to potential
//             predators of their formidable defenses.
//           </p>
//           <h3>Social Behavior</h3>
//           <p>
//             Despite their rugged exterior, crayfish engage in surprisingly
//             complex social interactions. They communicate through a series of
//             tactile and chemical signals, which govern their behaviors from
//             mating rituals to territorial disputes. This intricate social
//             structure is a testament to the evolutionary sophistication of these
//             seemingly simple creatures.
//           </p>
//           <h3>Conservation Concerns</h3>
//           <p>
//             While crayfish play a vital role in their ecosystems, many species
//             face threats from habitat destruction, pollution, and invasive
//             species. Conservation efforts are crucial to ensure the continued
//             survival of these fascinating creatures and the health of the
//             freshwater habitats they inhabit.
//           </p>
//           <p>
//             Crayfish are fascinating creatures that offer a window into the
//             complexities of freshwater ecosystems. Their behaviors, adaptations,
//             and interactions with their environment provide valuable insights
//             into the natural world.
//           </p>
//           <p>
//             Whether in the wild or in a home aquarium, crayfish continue to
//             captivate and inspire those who take the time to observe them. Their
//             story is one of resilience, adaptation, and the interconnectedness
//             of life in the aquatic realm.
//           </p>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default ViewBlog;
