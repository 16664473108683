import React, { useState, useCallback } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/uploadFile.css";
import { baseUrl } from "../utils/baseUrl";
import { Link } from "react-router-dom";
import ImportFileTemplate from "../assets/Import_file/Import_File_Template.xlsx";

const UploadFile = () => {
  const token = sessionStorage.getItem("token");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    } else {
      toast.error("Please select a valid .xlsx file");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx",
  });

  const handleUpload = async () => {
    if (!file) {
      toast.error("Please select a file");
      return;
    }

    if (!token) {
      toast.error("Token not found. Please log in.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const url = `${baseUrl}/upload`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(url, formData, { headers });

      if (response.status === 200) {
        toast.success("File uploaded successfully");
        // Reset the form after successful upload
        setFileName("");
        setFile(null);
      } else {
        toast.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An error occurred while uploading the file");
    }
  };

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div
          className="col-md-12 ms-2 mt-2"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Link
            to={ImportFileTemplate}
            download="ImportFileTemplate"
            target="_blank"
          >
            <Button
              variant="contained"
              className="btn btn-primary"
              style={{ textTransform: "capitalize" }}
            >
              Download Template
            </Button>
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="container">
          <div className="card card-import">
            <h3 style={{ color: "#4d16a4" }}>Import File</h3>
            <div className="drop_box" {...getRootProps()}>
              <input {...getInputProps()} />
              {!fileName ? (
                <>
                  <header>
                    <h4 style={{ color: "#4d16a4" }}>
                      Select file or Drag file here
                    </h4>
                  </header>
                  <p>Files Supported: .xlsx</p>
                  <button className="upload-btn">Choose File</button>
                </>
              ) : (
                <h4>{fileName}</h4>
              )}
            </div>
            {fileName && (
              <div className="form mt-3">
                <Button
                  variant="contained"
                  className="upload-btn"
                  onClick={handleUpload}
                >
                  Upload
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFile;

// import React, { useState, useCallback } from "react";
// import { useDropzone } from "react-dropzone";
// import { Button } from "@mui/material";
// import "../assets/css/uploadFile.css";

// const UploadFile = () => {
//   const [fileName, setFileName] = useState("");
//   const [file, setFile] = useState(null);

//   const onDrop = useCallback((acceptedFiles) => {
//     const selectedFile = acceptedFiles[0];
//     if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
//       setFileName(selectedFile.name);
//       setFile(selectedFile);
//     } else {
//       alert("Please select a valid .xlsx file");
//     }
//   }, []);

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     accept: ".xlsx",
//   });

//   const handleUpload = async () => {
//     if (!file) {
//       alert("Please select a file");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await fetch("/your-api-endpoint", {
//         method: "POST",
//         body: formData,
//       });

//       if (response.ok) {
//         alert("File uploaded successfully");
//         // Reset the form after successful upload
//         setFileName("");
//         setFile(null);
//       } else {
//         alert("File upload failed");
//       }
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       alert("An error occurred while uploading the file");
//     }
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div
//           className="col-md-12 ms-2 mt-2"
//           style={{ display: "flex", justifyContent: "flex-end" }}
//         >
//           <Button
//             variant="contained"
//             className="btn btn-primary"
//             onClick={() => {
//               // Handle download template logic here
//             }}
//             style={{ textTransform: "capitalize" }}
//           >
//             Download Template
//           </Button>
//         </div>
//       </div>
//       <div className="row mt-4">
//         <div className="container">
//           <div className="card card-import">
//             <h3 style={{ color: "#4d16a4" }}>Upload Files</h3>
//             <div className="drop_box" {...getRootProps()}>
//               <input {...getInputProps()} />
//               {!fileName ? (
//                 <>
//                   <header>
//                     <h4 style={{ color: "#4d16a4" }}>Select File here</h4>
//                   </header>
//                   <p>Files Supported: .xlsx</p>
//                   <button className="btn">Choose File</button>
//                 </>
//               ) : (
//                 <form>
//                   <div className="form">
//                     <h4>{fileName}</h4>
//                     <button
//                       type="button"
//                       className="btn"
//                       onClick={handleUpload}
//                     >
//                       Upload
//                     </button>
//                   </div>
//                 </form>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UploadFile;

// import React, { useRef, useState } from "react";
// import { Button } from "@mui/material";
// import "../assets/css/uploadFile.css";

// const UploadFile = () => {
//   const [fileName, setFileName] = useState("");
//   const [file, setFile] = useState(null);
//   const fileInputRef = useRef(null);

//   const handleButtonClick = () => {
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
//       setFileName(selectedFile.name);
//       setFile(selectedFile);
//     } else {
//       alert("Please select a valid .xlsx file");
//     }
//   };

//   const handleUpload = async () => {
//     if (!file) {
//       alert("Please select a file");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await fetch("/your-api-endpoint", {
//         method: "POST",
//         body: formData,
//       });

//       if (response.ok) {
//         alert("File uploaded successfully");
//         // Reset the form after successful upload
//         setFileName("");
//         setFile(null);
//       } else {
//         alert("File upload failed");
//       }
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       alert("An error occurred while uploading the file");
//     }
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div
//           className="col-md-12 ms-2 mt-2"
//           style={{ display: "flex", justifyContent: "flex-end" }}
//         >
//           <Button
//             variant="contained"
//             className="btn btn-primary"
//             onClick={() => {
//               // Handle download template logic here
//             }}
//             style={{ textTransform: "capitalize" }}
//           >
//             Download Template
//           </Button>
//         </div>
//       </div>
//       <div className="row mt-4">
//         <div className="container">
//           <div className="card card-import">
//             <h3 style={{ color: "#4d16a4" }}>Upload Files</h3>
//             <div className="drop_box">
//               {!fileName ? (
//                 <>
//                   <header>
//                     <h4 style={{ color: "#4d16a4" }}>Select File here</h4>
//                   </header>
//                   <p>Files Supported: .xlsx</p>
//                   <input
//                     type="file"
//                     hidden
//                     accept=".xlsx"
//                     ref={fileInputRef}
//                     style={{ display: "none" }}
//                     onChange={handleFileChange}
//                   />
//                   <button className="btn" onClick={handleButtonClick}>
//                     Choose File
//                   </button>
//                 </>
//               ) : (
//                 <form>
//                   <div className="form">
//                     <h4>{fileName}</h4>
//                     <button
//                       type="button"
//                       className="btn"
//                       onClick={handleUpload}
//                     >
//                       Upload
//                     </button>
//                   </div>
//                 </form>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UploadFile;
