import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { data } from "./makeData";
import { Row, Col, Container } from "react-bootstrap";
import { Grid, IconButton } from "@mui/material";
import {
  Delete as DeleteIcon,
  GroupAdd as GroupAddIcon,
  RemoveRedEye,
} from "@mui/icons-material";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { TableSortLabel } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { DateRangePicker } from "rsuite";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../utils/baseUrl";
import TableSkeleton from "../utils/TableSkeleton";

const notify = () => toast("Save Successfully");
Modal.setAppElement("#root"); // Required for accessibility

const Employee = () => {
  const [clientCreateData, setclientCreateData] = useState("");
  const [clientCreatedDate, setclientCreatedDate] = useState([]);
  const convertToMMDDYYYY = (isoDateString) => {
    const date = new Date(isoDateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-based month
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    setclientCreateData(`${month}/${day}/${year}`);
    return `${month}/${day}/${year}`;
  };

  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [tagModalIsOpen, setTagModalIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [taggedClients, setTaggedClients] = useState([]);
  const [mobile, setMobile] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [newEmployeeName, setNewEmployeeName] = useState("");
  const [newEmployeeEmail, setNewEmployeeEmail] = useState("");
  const [employees, setEmployees] = useState([]);

  // -------------create new employee----------------

  const [fullName, setFullName] = useState("");
  const [NewEmail, setNewEmail] = useState("");
  const [NewMobile, setNewMobile] = useState("");
  const [NewDesign, setNewDesign] = useState("");

  const [data, setData] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [progressData, setprogressData] = useState();

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    GetInprogressClient();
  }, []);

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      size: 30,
    }),
    columnHelper.accessor("Email", {
      header: "Email",
      size: 30,
    }),
    columnHelper.accessor("PhoneNo", {
      header: "Mobile No",
      size: 30,
    }),
    columnHelper.accessor("Designation", {
      header: "Designation",
      size: 30,
    }),
    // columnHelper.accessor("TaggedClient", {
    //   header: "Tagged Client",
    //   size: 60,
    //   Cell: (cell) => (
    //     <IconButton onClick={() => openViewModal(cell.row.original)}>
    //       <RemoveRedEye color="error" />
    //     </IconButton>
    //   ),
    // }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const rowData = employeeList.map((employee) => ({
      name: employee.name,
      email: employee.Email,
      phoneNo: employee.PhoneNo,
      designation: employee.Designation,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      name: row.original.name,
      email: row.original.Email,
      phoneNo: row.original.PhoneNo,
      designation: row.original.Designation,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: employeeList,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell, row, table }) => [
      <Box sx={{ display: "flex" }}>
        <IconButton onClick={() => openEditModal(row.original)}>
          <EditIcon color="error" />
        </IconButton>
        {/* <IconButton onClick={() => openTagModal(row.original._id)}>
          <GroupAddIcon  color="error"/>
        </IconButton> */}
        <IconButton onClick={() => deleteEmployee(row.original._id)}>
          <DeleteIcon color="error" />
        </IconButton>
      </Box>,
      /*
      <TableCell align="center">
      <Button
        style={{ marginRight: "20px" }}
        variant="contained"
        onClick={() => openEditModal(row.original)}>
        Edit
      </Button>

      <Button
       style={{marginRight:"20px"}}
        variant="contained"
        onClick={() => openTagModal(row.original)}>
      Tag Client
      </Button>

    <Button 
      style={{marginRight:"20px"}}
      variant="contained" 
      onClick={(e) => handleDelete(row.original._id)} 
      
      // onClick={() => handleDelete(employee.id)} 
    >
      Delete 
    </Button> 

  </TableCell>
  */
    ],

    // initialState: { showColumnFilters: true },
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // columnFilterDisplayMode: "popover",
    // paginationDisplayMode: "pages",

    // enableColumnResizing: true,
    // enableRowPinning: true,
    // enableRowSelection: true,
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const fetchEmployees = async () => {
    try {
      const url = `${baseUrl}/Employee_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const employees = response?.data?.data;

      setLoading(false);
      setEmployeeList(employees);
      setLoading(false);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const fetchClients = async () => {
    try {
      const url = `${baseUrl}/Client_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const clients = response.data.data;
      setClientList(clients);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const TagclientApi = async () => {
    try {
      const url = `${baseUrl}/tag`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        client_name: selectedClient,
        employee_name: selectedEmployee.name,
      };

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      if (data.error == false) {
        employeeClienttagged();
      }
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const employeeClienttagged = async (employeeId) => {
    try {
      const url = `${baseUrl}/employeeclientstagged/${employeeId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const taggedClientsData = response.data.data;
      setTaggedClients(taggedClientsData);
    } catch (error) {
      console.error("Error fetching tagged clients data:", error);
    }
  };

  const updateEmployee = async (employeeId) => {
    try {
      const url = `${baseUrl}/employee_update/${employeeId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: Name,
        Email: email,
        Designation: designation,
      };

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      if (data.error == true) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        setEmployees({
          name: "",
          email: "",
          Designation: "",
        });
      }
      fetchEmployees();
    } catch (error) {
      console.error("Error connecting to API", error);
      console.error("Error connecting to API", error);
    }
  };

  const GetInprogressClient = async () => {
    try {
      const url = `${baseUrl}/getProposal`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      // const employees = response.data.data;
      setprogressData(response.data.data.PendingProposals);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleDelete = async (employeeId) => {
    try {
      const url = `${baseUrl}/employee_delete/${employeeId}`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.delete(url, { headers });
      const data = response.data;
      // if(data.message ===" Are you want to delete "){
      //   fetchEmployees();
      // }
      fetchEmployees();
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
    // }
  };

  const deleteEmployee = async (employeeId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // =============================================================================

        try {
          const url = `${baseUrl}/employee_delete/${employeeId}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          GetInprogressClient();
          fetchEmployees();
        } catch (error) {
          console.error("Error connecting to API", error);
        }

        // =============================================================================
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Employee has been deleted.",
          icon: "success",

          customClass: {
            confirmButton: "my-custom-button-class",
          },
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your Employee is safe :)",
          icon: "error",
        });
      }
    });
  };

  // -------------------------------create new employee----------------------
  const CreateNewEmp = async () => {
    try {
      const url = `${baseUrl}/employee_create`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: newEmployeeName,
        Email: newEmployeeEmail,
        PhoneNo: NewMobile,
        Designation: NewDesign,
      };

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;

      if (data.message == true) {
        fetchEmployees();
        toast.success(data.message);
        setNewEmployeeName("");
        setNewEmployeeEmail("");
        setNewMobile("");
        setNewDesign("");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleNewEmployeeMobileChange = (e) => {
    setNewMobile(e.target.value);
  };

  const handlenewDesignantion = (e) => {
    setNewDesign(e.target.value);
  };

  // ------------------------end---------------------------------

  //---------------------------------MODAL functions------------------------------------

  const openEditModal = (employee) => {
    setSelectedEmployee(employee);
    setName(employee.name);
    setEmail(employee.Email);
    setDesignation(employee.Designation);
    setMobile(employee.PhoneNo);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleMobilechange = (e) => {
    setMobile(e.target.value);
  };
  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
  };

  const handleSave = (employee) => {
    updateEmployee(employee._id);
    closeEditModal();
  };

  const openTagModal = async (employee) => {
    setSelectedEmployee(employee);
    await fetchClients();
    setTagModalIsOpen(true);
  };

  const closeTagModal = () => {
    setTagModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const handleTag = async () => {
    TagclientApi();
    closeTagModal();
    fetchEmployees();
    // employeeClienttagged();
  };

  const openViewModal = async (employee) => {
    setSelectedEmployee(employee);
    await employeeClienttagged(employee._id);
    setViewModalIsOpen(true);
  };

  const closeViewModal = () => {
    setViewModalIsOpen(false);
    setTaggedClients([]);
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const handleNewEmployeeNameChange = (e) => {
    setNewEmployeeName(e.target.value);
  };

  const handleNewEmployeeEmailChange = (e) => {
    setNewEmployeeEmail(e.target.value);
  };

  const handleCreateEmployee = () => {
    let valid = true;

    if (newEmployeeName.trim() === "") {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    if (newEmployeeEmail.trim() === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(newEmployeeEmail)) {
      setEmailError("Invalid email format");
      valid = false;
    } else {
      setEmailError("");
    }

    if (NewMobile.trim() === "") {
      setMobileError("Mobile number is required");
      valid = false;
    } else if (!/^\d{10}$/.test(NewMobile.trim())) {
      setMobileError("Mobile number should be 10 digits");
      valid = false;
    } else {
      setMobileError("");
    }

    if (NewDesign.trim() === "") {
      setDesignationError("Designation is required");
      valid = false;
    } else {
      setDesignationError("");
    }

    if (valid) {
      CreateNewEmp();
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    const filteredDates = employeeList?.filter((dateString) => {
      return query == dateString.name;
    });

    setfilteredClients(filteredDates);
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const dateRangePickerRef = useRef();

  useEffect(() => {
    // Example of using querySelector to get the value when component mounts or updates
    const getDateRangeValue = () => {
      const dateRangeElement = document.querySelector("#date-range-picker");
      if (dateRangeElement) {
        console.log(
          "Selected Date Range via querySelector:",
          dateRangeElement.value
        );
      }
    };

    getDateRangeValue();
  }, [dateRange]);

  const [value, setValue] = React.useState([new Date(""), new Date("")]);

  var firstDate;
  var lastDate;
  const [filteredClients, setfilteredClients] = useState(employeeList);

  useEffect(() => {
    firstDate = convertToMMDDYYYY(value[0]);
    lastDate = convertToMMDDYYYY(value[1]);

    const filteredDates = employeeList?.filter((dateString) => {
      const date = convertToMMDDYYYY(dateString.createdAt);
      return date >= firstDate && date <= lastDate;
    });

    setfilteredClients(filteredDates);
  }, [value]);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [designationError, setDesignationError] = useState("");

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="dashboard section">
          <ToastContainer />
          <div className="dashboard section">
            <Card className="mb-4" style={{ width: "100%" }}>
              <h2 className="TABLE_HEADING">ADD EMPLOYEE</h2>
              <Container fluid>
                <Row className="mb-4">
                  <Col xs={12}>
                    <Box
                      component="form"
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "0.5rem",
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="name"
                            label="Name"
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="text"
                            value={newEmployeeName}
                            onChange={handleNewEmployeeNameChange}
                            error={!!nameError}
                            helperText={nameError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="email"
                            label="Email"
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="email"
                            value={newEmployeeEmail}
                            onChange={handleNewEmployeeEmailChange}
                            error={!!emailError}
                            helperText={emailError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="mobile"
                            label="Mobile No."
                            required
                            fullWidth
                            size="small"
                            inputProps={{ maxLength: 10 }}
                            variant="outlined"
                            type="text"
                            value={NewMobile}
                            onChange={handleNewEmployeeMobileChange}
                            error={!!mobileError}
                            helperText={mobileError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="designation"
                            label="Designation"
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="text"
                            value={NewDesign}
                            onChange={handlenewDesignantion}
                            error={!!designationError}
                            helperText={designationError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Button
                            variant="contained"
                            onClick={handleCreateEmployee}
                            className="button-width"
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Col>
                </Row>
              </Container>
            </Card>
            <Row>
              <Col xs={12}>
                <h2 className="TABLE_HEADING"></h2>
                {loading ? (
                  <TableSkeleton />
                ) : (
                  <MaterialReactTable table={table} />
                )}
              </Col>
            </Row>

            {/* <Button
            variant="contained"
            endIcon={<AddIcon sx={{ color: "white !important" }} />}
            onClick={openCreateModal}
            sx={{ marginBottom: "20px", textTransform: "capitalize",marginTop:"11px" }}>
            Create New Employee
          </Button> */}
          </div>
        </div>
        <div className="col-sm-6 d-flex justify-content-end">
          <form>
            {/* <DateRangePicker
             character=" – "
                style={{
                  color: "#4a2a7d !important",
                  border: " solid 2px #4a2a7d",
                  marginRight: "20px",
                }}
                value={value>=1?value:null}
                onChange={setValue}
                          placeholder="select date"
                         id= "#date-range-picker"
            /> */}
            {/* <input
              type="text"
              placeholder="Search..."
              value={query}
              onChange={handleInputChange}
              id="searchInput"
            />
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary"
                          id="searchSubmit"
                      onClick={handleSearch}
                      >
              Search
            </Button> */}
          </form>
        </div>
        <div className="col-md-12" style={{ display: "none" }}>
          {/* Table Header */}

          {/* =========================================================================== */}

          <Paper>
            <TableContainer>
              <Table>
                {/* <TableHead sx={{ backgroundColor: "#F2F3F7", color: "black" }}>
                  <TableRow>
                    <TableCell align="center">Sr No</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Mobile No</TableCell>
                    <TableCell align="center">Tagged Client</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  {/* =============================================================== */}
                  {filteredClients.map((row, index) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor: "#ffff",
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.Email}</TableCell>
                      <TableCell align="center">{row.PhoneNo}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          id="demo-positioned-button"
                          aria-controls={
                            open ? "demo-positioned-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={() => openViewModal(row)}
                          disableElevation
                        >
                          View
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={() => openEditModal(row)}
                        >
                          Edit
                        </Button>

                        <Button
                          variant="contained"
                          onClick={() => openTagModal(row)}
                        >
                          Tag Client
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {query !== ""
                    ? filteredClients.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor: "#ffff",
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.Email}</TableCell>
                          <TableCell align="center">{row.PhoneNo}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              onClick={() => openEditModal(row)}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              onClick={() => openTagModal(row)}
                            >
                              Tag Client
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Button
                              variant="contained"
                              id="demo-positioned-button"
                              aria-controls={
                                open ? "demo-positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={() => openViewModal(row)}
                              disableElevation
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    : employeeList.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor: "#ffff",
                          }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.Email}</TableCell>
                          <TableCell align="center">{row.PhoneNo}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              id="demo-positioned-button"
                              aria-controls={
                                open ? "demo-positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={() => openViewModal(row)}
                              disableElevation
                            >
                              View
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              style={{ marginRight: "20px" }}
                              variant="contained"
                              onClick={() => openEditModal(row)}
                            >
                              Edit
                            </Button>

                            <Button
                              variant="contained"
                              onClick={() => openTagModal(row)}
                            >
                              Tag Client
                            </Button>

                            <Button
                              style={{ marginRight: "20px" }}
                              variant="contained"
                              onClick={(e) => handleDelete(row._id)}
                            >
                              Delete
                            </Button>
                            {/* <Button
                              variant="contained"
                              onClick={() => deletePurposal(row)}>
                              Delete
                            </Button> */}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={employeeList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          {/* =========================================================================================== */}
        </div>
      </div>
      {/* End Employee List */}

      {/* Edit Employee Modal */}
      {selectedEmployee && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Employee"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit Employee</h3>
          <form>
            {/* <div className="form-group p-2">
              <label className="label-color">User ID</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={selectedEmployee._id}
                readOnly
              />
            </div> */}

            <div className="form-group p-2">
              <label className="label-color">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={Name}
                onChange={handleNameChange}
              />
            </div>
            <div className="form-group p-2">
              <label className="label-color">Email</label>
              <input
                type="email"
                className="form-control"
                name="Email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="form-group p-2">
              <label className="label-color">Designation</label>
              <input
                type="text"
                className="form-control"
                name="designation"
                value={designation}
                onChange={handleDesignationChange}
              />
            </div>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedEmployee)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
      {/* End Edit Employee Modal */}

      {/* Tag Employee Modal */}
      {selectedEmployee && (
        <Modal
          isOpen={tagModalIsOpen}
          onRequestClose={closeTagModal}
          contentLabel="Tag Employee"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Tag Employee</h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleTag();
            }}
          >
            <div className="form-group p-2">
              <label>Employee Name</label>
              <input
                type="text"
                name="name"
                value={selectedEmployee.name}
                readOnly
              />
            </div>

            <div className="form-group p-2">
              <label>Client Name</label>
              <select value={selectedClient} onChange={handleClientChange}>
                <option value="">Select Client</option>
                {clientList.map((client) => (
                  <option key={client.id} value={client.name}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="div p-2">
              <Button
                variant="contained"
                type="button"
                className="btn btn-secondary mt-2"
                style={{ float: "left" }}
                onClick={closeTagModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="btn btn-primary mt-2"
                style={{ float: "right" }}
              >
                Tag
              </Button>
            </div>
          </form>
        </Modal>
      )}
      {/* End Tag Employee Modal */}

      {/* View Tagged Clients Modal */}
      <Modal
        isOpen={viewModalIsOpen}
        onRequestClose={closeViewModal}
        contentLabel="View Tagged Clients"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
          },
        }}
      >
        {/* ====================================================================== */}

        <h3 className="text-center  form_header">Tagged Clients</h3>
        {taggedClients.length > 0 ? (
          taggedClients.map((taggedClient, index) => (
            <div className="card mb-3 pt-4">
              <div className="row">
                <div className="col-sm-6 text-center">
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    Employee Name:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    Employee Mobile:{" "}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    Employee Email:{" "}
                  </p>
                </div>
                <div className="col-sm-5 text-left">
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    {taggedClient.name}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    {taggedClient.mobileNo}
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#4a2a7d",
                      fontWeight: "bold",
                    }}
                  >
                    {taggedClient.Email}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">
            No Emolpyee tagged with this Client.
          </div>
        )}

        {/* ============================================================================ */}

        {/* <h3 className="text-center fw-bold form_header">Tagged Clients</h3> */}
        {/* {taggedClients.length > 0 ? (
          taggedClients.map((taggedClient, index) => (
            <div
              key={index}
              className="card mb-3"
              style={{ boxShadow: "1px 3px 5px 0px rgba(0, 0, 0, 0.6)" }}>
              <table>
                <tbody className="fs-5">
                  <tr>
                    <td className="text-left p-2">Client Name: </td>
                    <td className="p-2" style={{ textAlign: "right" }}>
                      {taggedClient.name}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="p-2">Client Mobile: </td>
                    <td className="p-2" style={{ textAlign: "right" }}>
                      {taggedClient.mobileNo}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2">Client Email: </td>
                    <td className="p-2" style={{ textAlign: "right" }}>
                      {taggedClient.Email}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))
        ) : (
          <div className="text-center">
            No client tagged with this employee.
          </div>
        )} */}
        <Button
          variant="contained"
          type="button"
          className="btn btn-secondary mt-2"
          onClick={closeViewModal}
        >
          Close
        </Button>
      </Modal>
      {/* End View Tagged Clients Modal */}

      {/* Create New Employee Modal */}
      <Modal
        isOpen={createModalIsOpen}
        onRequestClose={closeCreateModal}
        contentLabel="Create New Employee"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
          },
        }}
      >
        <h3 className="text-center fw-bold form_header">Create New Employee</h3>
        <form>
          <div className="row">
            <div className="col">
              <div className="form-group p-2">
                <label>Full Name</label>
                <input
                // type="text"
                // onChange={handleNewEmployeeNameChange}
                // required
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group p-2">
                <label>Email</label>
                <input
                // type="email"
                // onChange={handleNewEmployeeEmailChange}
                // required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group p-2">
                <label>Mobile No</label>
                <input
                // type="text"
                // // value={newEmployeeName}
                // onChange={handleNewEmployeeMobileChange}
                // required
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group p-2">
                <label>Designation</label>
                <input
                // value={newEmployeeEmail}
                // type="email"
                // onChange={handlenewDesignantion}
                // required
                />
              </div>
            </div>
          </div>

          <div className="div m-3">
            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              onClick={closeCreateModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={handleCreateEmployee}
            >
              Create
            </Button>
          </div>
        </form>
      </Modal>
      {/* End Create New Employee Modal */}
    </div>
  );
};

export default Employee;
