import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Card, Row, Col, Container } from "react-bootstrap";
import Modal from "react-modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { mkConfig, generateCsv, download } from "export-to-csv";
import moment from "moment/moment";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TotalAmount from "../assets/Icons/Untitled design (2).png";
import TotalGoldAmount from "../assets/Icons/Untitled design (3).png";
import TotalSilverAmount from "../assets/Icons/Untitled design (5).png";
import TotalGoldGrams from "../assets/Icons/Untitled design (4).png";
import TotalSilverGrams from "../assets/Icons/Untitled design (6).png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../utils/baseUrl";
import TableSkeleton from "../utils/TableSkeleton";

const notify = () => toast("Save Successfully");
Modal.setAppElement("#root"); // Required for accessibility

const Report = () => {
  const [clientCreateData, setclientCreateData] = useState("");
  const [clientCreatedDate, setclientCreatedDate] = useState([]);
  const convertToMMDDYYYY = (isoDateString) => {
    const date = new Date(isoDateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-based month
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    setclientCreateData(`${month}/${day}/${year}`);
    return `${month}/${day}/${year}`;
  };

  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [tagModalIsOpen, setTagModalIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [taggedClients, setTaggedClients] = useState([]);
  const [mobile, setMobile] = useState("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [newEmployeeName, setNewEmployeeName] = useState("");
  const [newEmployeeEmail, setNewEmployeeEmail] = useState("");
  const [employees, setEmployees] = useState([]);
  const [TotalAugmontData, setTotalAugmontData] = useState({});

  // -------------create new employee----------------
  const [NewMobile, setNewMobile] = useState("");
  const [NewDesign, setNewDesign] = useState("");
  const [progressData, setprogressData] = useState([]);

  useEffect(() => {
    // fetchEmployees();
  }, []);

  useEffect(() => {
    GetInprogressClient();
    getTotalAugmontData();
  }, []);

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("client_id.name", {
      header: "Client Name",
      size: 30,
    }),
    columnHelper.accessor("metal_type", {
      header: "Metal Type",
      size: 30,
    }),

    columnHelper.accessor("total_amount", {
      header: "Total Amount",
      size: 40,
      Cell: (cell) => "₹" + cell.row.original.total_amount,
    }),
    columnHelper.accessor(
      (row) => moment(row.createdAt).format("DD-MM-YYYY"),

      {
        header: "Date",
        size: 40,
      }
    ),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const rowData = progressData.map((data) => ({
      clientName: data.client_id.name,
      metalType: data.metal_type,
      totalAmount: data.total_amount,
      date: data.createdAt.split("T")[0],
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      clientName: row.original.client_id.name,
      metalType: row.original.metal_type,
      totalAmount: row.original.total_amount,
      date: row.original.createdAt.split("T")[0],
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: progressData,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const getTotalAugmontData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${baseUrl}/getTotalInvestments`, {
        headers,
      });
      setTotalAugmontData(response.data.data);
    } catch (error) {
      console.error("Error fetching total Augmont data:", error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const url = `${baseUrl}/Employee_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const employees = response.data.data;

      employees?.map((index, item) => {
        setclientCreatedDate(item.createdAt);

        // employees.set("slno",++item)
        index.slno = ++item;
      });
      // {employees.map((employee) => (
      //   <li key={employee.id}>
      //     {employee.name} - {employee.designation}
      //     <Button
      //       style={{ marginRight: "20px" }}
      //       variant="contained"
      //       onClick={() => handleDelete(employee.id)}
      //     >
      //       Delete
      //     </Button>
      //   </li>
      setEmployees(employees);
      setEmployeeList(employees);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const fetchClients = async () => {
    try {
      const url = `${baseUrl}/Client_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const clients = response.data.data;
      setClientList(clients);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const TagclientApi = async () => {
    try {
      const url = `${baseUrl}/tag`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        client_name: selectedClient,
        employee_name: selectedEmployee.name,
      };

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      if (data.error == false) {
        employeeClienttagged();
      }
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const employeeClienttagged = async (employeeId) => {
    try {
      const url = `${baseUrl}/employeeclientstagged/${employeeId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const taggedClientsData = response.data.data;
      setTaggedClients(taggedClientsData);
    } catch (error) {
      console.error("Error fetching tagged clients data:", error);
    }
  };

  const updateEmployee = async (employeeId) => {
    try {
      const url = `${baseUrl}/employee_update/${employeeId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: Name,
        Email: email,
        Designation: designation, // Changed from phone to designation
      };
      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      // fetchEmployees();
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const GetInprogressClient = async () => {
    try {
      const url = `${baseUrl}/transactionReport`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      // const employees = response.data.data;
    
      setLoading(false);
      setprogressData(response.data.data.transaction_history);

    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleDelete = async (employeeId) => {
    // const confirmDelete = window.confirm("Are you sure you want to delete?");

    //   if (confirmDelete) {
    try {
      const url = `${baseUrl}/employee_delete/${employeeId}`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.delete(url, { headers });
      const data = response.data;
      // if(data.message ===" Are you want to delete "){
      //   fetchEmployees();
      // }
      //  fetchEmployees(); // Refresh the employee list after deletion
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
    // }
  };

  const CreateNewEmp = async () => {
    try {
      const url = `${baseUrl}/employee_create`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: newEmployeeName,
        Email: newEmployeeEmail,
        PhoneNo: NewMobile,
        Designation: NewDesign,
      };


      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      if (data.message === "Employee created successfully !!!!") {
        // fetchEmployees();
      }
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleNewEmployeeMobileChange = (e) => {
    setNewMobile(e.target.value);
  };

  const handlenewDesignantion = (e) => {
    setNewDesign(e.target.value);
  };

  // ------------------------end---------------------------------

  const openEditModal = (employee) => {
    setSelectedEmployee(employee);
    setName(employee.name);
    setEmail(employee.Email);
    setDesignation(employee.Designation);
    setMobile(employee.PhoneNo); // Set the designation from employee data
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleMobilechange = (e) => {
    setMobile(e.target.value);
  };
  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
  };

  const handleSave = (employee) => {
    updateEmployee(employee._id);
    closeEditModal();
  };

  const openTagModal = async (employee) => {
    setSelectedEmployee(employee);
    await fetchClients();
    setTagModalIsOpen(true);
  };

  const closeTagModal = () => {
    setTagModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const handleTag = async () => {
    TagclientApi();
    closeTagModal();
    // fetchEmployees();
    // employeeClienttagged();
  };

  const openViewModal = async (employee) => {
    setSelectedEmployee(employee);
    await employeeClienttagged(employee._id);
    setViewModalIsOpen(true);
  };

  const closeViewModal = () => {
    setViewModalIsOpen(false);
    setTaggedClients([]);
  };

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  };

  const handleNewEmployeeNameChange = (e) => {
    setNewEmployeeName(e.target.value);
  };

  const handleNewEmployeeEmailChange = (e) => {
    setNewEmployeeEmail(e.target.value);
  };

  const handleCreateEmployee = () => {
    CreateNewEmp();
    // closeCreateModal();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const dateRangePickerRef = useRef();

  useEffect(() => {
    // Example of using querySelector to get the value when component mounts or updates
    const getDateRangeValue = () => {
      const dateRangeElement = document.querySelector("#date-range-picker");
      if (dateRangeElement) {
        console.log(
          "Selected Date Range via querySelector:",
          dateRangeElement.value
        );
      }
    };

    getDateRangeValue();
  }, [dateRange]);

  const [value, setValue] = React.useState([new Date(""), new Date("")]);

  const datearr = [
    "2024-05-28T08:04:06.028Z",
    "2024-07-28T08:04:06.028Z",
    "2024-01-28T08:04:06.028Z",
    "2024-09-28T08:04:06.028Z",
  ];
  var firstDate;
  var lastDate;
  const [filteredClients, setfilteredClients] = useState(employeeList);

  useEffect(() => {
    firstDate = convertToMMDDYYYY(value[0]);
    lastDate = convertToMMDDYYYY(value[1]);

    const filteredDates = employeeList?.filter((dateString) => {
      const date = convertToMMDDYYYY(dateString.createdAt);
      return date >= firstDate && date <= lastDate;
    });

    setfilteredClients(filteredDates);
  }, [value]);

  return (
    <div className="dashboard section">
      <Container fluid>
        <Row className="mb-4">
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-4" style={{ width: "100%", height: "90%" }}>
              <Card.Body className="d-flex">
                <img
                  src={TotalAmount}
                  alt="Total Amount"
                  width="100"
                  className="me-3"
                />
                <div>
                  <Typography variant="h6">Total Amount</Typography>
                  <Typography variant="h6">
                    ₹{TotalAugmontData.totalAumAmount}
                  </Typography>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-4" style={{ width: "100%", height: "90%" }}>
              <Card.Body className="d-flex">
                <img
                  src={TotalGoldAmount}
                  alt="Total Gold Amount"
                  width="100"
                  className="me-3"
                />
                <div>
                  <Typography variant="h6">Gold Amount</Typography>
                  <Typography variant="h6">
                    ₹{TotalAugmontData.totalGoldAmount}
                  </Typography>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-4" style={{ width: "100%", height: "90%" }}>
              <Card.Body className="d-flex">
                <img
                  src={TotalSilverAmount}
                  alt="Total Silver Amount"
                  width="100"
                  className="me-3"
                />
                <div>
                  <Typography variant="h6">Silver Amount</Typography>
                  <Typography variant="h6">
                    ₹{TotalAugmontData.totalSilverAmount}
                  </Typography>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="TABLE_HEADING">Total Sale Report</h2>

            {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Report;
