import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { baseUrl } from "../../utils/baseUrl";
import TableSkeleton from "../../utils/TableSkeleton";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("proposal_no", {
    header: "Order Number",
    size: 40,
  }),
  columnHelper.accessor("subReferenceId", {
    header: "Sub Reference Id",
    size: 40,
  }),
  columnHelper.accessor("clientName", {
    header: "Client",
    size: 40,
    Cell: (cell) => cell.row.original.client_id.name,
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: " 7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.sip_status}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => "₹ " + cell.row.original.amount,
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const SipHistory = () => {
  const Token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [executedSip, setExecutedSip] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    GetExecutedAPI();
  }, []);

  const GetExecutedAPI = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/sip/listExecuted`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const sips = response.data.data;
      sips.forEach((proposal, index) => {
        proposal.slno = index + 1;
      });
      setLoading(false);
      setExecutedSip(sips);
      setEmployees(sips);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleExportAllData = () => {
    const rowData = executedSip.map((data) => ({
      proposal_no: data.proposal_no,
      clientName: data.client_id.name,
      metal_type: data.metal_type,
      amount: data.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportSelectedRows = (rows) => {
    const rowData = rows.map((row) => ({
      proposal_no: row.original.proposal_no,
      clientName: row.original.client_id.name,
      metal_type: row.original.metal_type,
      amount: row.original.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ row }) => [
      <Box>
        <IconButton color="error" onClick={() => cancelSIP(row.original)}>
          <span
            style={{
              backgroundColor: "rgb(74 42 125)",
              padding: " 7px 14px",
              borderRadius: "12px",
              fontSize: "13px",
              color: "white",
            }}
          >
            Cancel
          </span>
        </IconButton>
      </Box>,
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleExportAllData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() =>
            handleExportSelectedRows(table.getSelectedRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const cancelSIP = async (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Retrieve Token inside the function to ensure it's available
          const Token = sessionStorage.getItem("token");

          if (!Token) {
            console.error("Token is not available");
            return;
          }

          const url = `${baseUrl}/partnerPortal/sip/cancel/${row.subReferenceId}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Token}`,
          };

          // Ensure an empty body object is provided for POST request
          const response = await axios.post(url, {}, { headers });

          if (response.data.error == false) {
            swalWithBootstrapButtons.fire({
              title: "Cancel!",
              text: response.data.message,
              icon: "success",
            });
            GetExecutedAPI();
          } else {
            swalWithBootstrapButtons.fire({
              title: "Error!",
              text: response.data.message,
              icon: "error",
            });
          }
        } catch (error) {
          console.error("Error connecting to API", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your SIP is safe :)",
          icon: "error",
        });
      }
    });
  };

  return (
    <>{loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}</>
  );
};

export default SipHistory;
