import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box } from "@mui/material";
import { baseUrl } from "../../utils/baseUrl";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("name", {
    header: "Client Name",
    size: 40,
  }),
  columnHelper.accessor("mobileNo", {
    header: "Mobile Number",
    size: 40,
  }),
];

const CreateNewOrder = () => {
  const [clientList, setClientList] = useState([]);
  const Token = sessionStorage.getItem("token");
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientList();
  }, []);

  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableRowSelection: false,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell }) => (
      <Box>
        <Button
          type="button"
          variant="contained"
          onClick={() => addOrder(cell.row.original._id)}
          style={{ textTransform: "none" }}
        >
          Place Order
        </Button>
      </Box>
    ),
  });

  const fetchClientList = async () => {
    try {
      const url = `${baseUrl}/Client_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const clients = response.data.data;
      setClientList(clients);
      setEmployees(clients);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const addOrder = (clientId) => {
    navigate(`/product/${clientId}`);
    // navigate("/product", { state: { clientId } });
  };

  return <MaterialReactTable table={table} />;
};

export default CreateNewOrder;

// import React, { useState, useEffect, useCallback } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import Button from "@mui/material/Button";
// import { useDispatch } from "react-redux";
// import { Addclient } from "../../redux/clientDataSlice";
// import {
//   MaterialReactTable,
//   createMRTColumnHelper,
//   useMaterialReactTable,
// } from "material-react-table";
// import { mkConfig } from "export-to-csv";
// import { Box } from "@mui/material";
// import { baseUrl } from "../../utils/baseUrl";

// const columnHelper = createMRTColumnHelper();

// const columns = [
//   columnHelper.accessor("name", {
//     header: "Client Name",
//     size: 40,
//   }),
//   columnHelper.accessor("mobileNo", {
//     header: "Mobile Number",
//     size: 40,
//   }),
// ];

// const csvConfig = mkConfig({
//   fieldSeparator: ",",
//   decimalSeparator: ".",
//   useKeysAsHeaders: true,
// });
// const CreateNewOrder = () => {
//   const [clientList, setClientList] = useState([]);
//   const Token = sessionStorage.getItem("token");
//   const [employees, setEmployees] = useState([]);
//   const navigate = useNavigate();
//   useEffect(() => {
//     FetchClientList();
//   }, []);

//   const table = useMaterialReactTable({
//     columns,
//     data: employees,
//     enableRowSelection: false,
//     enableGlobalFilter: true,
//     columnFilterDisplayMode: "popover",
//     enableColumnActions: false,
//     enableColumnFilters: false,
//     enableSorting: true,
//     enableDensityToggle: false,
//     enableHiding: false,
//     maxSize: 100,
//     enableRowActions: true,
//     positionActionsColumn: "last",
//     muiTableHeadCellProps: {
//       sx: (theme) => ({
//         color: "#4a2a7d",
//         background: "#DCDAFF",
//         fontWeight: "normal",
//         fontSize: "10px",
//       }),
//     },
//     renderRowActions: ({ cell, row, table }) => [
//       <Box>
//         <Button
//           type="button"
//           variant="contained"
//           onClick={() => addOrder(cell.row.original)}
//           style={{ textTransform: "none" }}
//         >
//           Place Order
//         </Button>
//       </Box>,
//     ],
//   });
//   const FetchClientList = async () => {
//     try {
//       const url = `${baseUrl}/Client_List`;
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };
//       const response = await axios.get(url, { headers });
//       const clients = response.data.data;
//       setClientList(clients);
//       const employees = response.data.data;
//       // setprogressData(response.data.data.PendingProposals);
//       employees?.map((index, item) => {
//         // console.log("Employee list=", employees.index.id);
//         //  setclientCreatedDate(item.createdAt);

//         // employees.set("slno",++item)
//         index.slno = ++item;
//       });

//       setEmployees(employees);
//       console.log("Client list=", clients);
//     } catch (error) {
//       console.error("Error connecting to API", error);
//     }
//   };

//   const dispatch = useDispatch();

//   const addOrder = (client) => {
//     console.log("client", client);
//     if (client._id !== "") {
//       dispatch(Addclient(client));
//       navigate("/product", { state: { client } });
//     }
//   };
//   return <MaterialReactTable table={table} />;
// };

// export default CreateNewOrder;
